import { useEffect, useRef, useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import './b2bClass.css'
import { Card, Col, Container, Form, Nav, Navbar, Row, ToggleButton, ToggleButtonGroup } from 'react-bootstrap';
import { FaCalendarAlt, FaCheckCircle, FaClipboardList, FaClock, FaIndustry, FaMapMarkerAlt, FaMicrophone, FaShareAlt, FaUser, FaVideo } from 'react-icons/fa';
import { useNavigate } from 'react-router';


const VideoConfrancing = ({ data }) => {
    const [show, setShow] = useState(true);
    const [isMuted, setIsMuted] = useState(false);
    const [isCameraOff, setIsCameraOff] = useState(false);
    const [isMeetingStarted, setIsMeetingStarted] = useState(false);
    const [timer, setTimer] = useState(300); // 5 minutes in seconds
    const videoRef = useRef(null);

    const [isSharingScreen, setIsSharingScreen] = useState(false);


    useEffect(() => {
        const startVideo = async () => {
            try {
                const stream = await navigator.mediaDevices.getUserMedia({ video: !isCameraOff, audio: !isMuted });
                if (videoRef.current) {
                    videoRef.current.srcObject = stream;
                }
            } catch (error) {
                console.error('Error accessing the camera:', error);
            }
        };

        startVideo();

        return () => {
            if (videoRef.current && videoRef.current.srcObject) {
                let tracks = videoRef.current.srcObject.getTracks();
                tracks.forEach((track) => track.stop());
            }
        };
    }, [isMuted, isCameraOff]);

    useEffect(() => {
        let timerId;
        if (isMeetingStarted && timer > 0) {
            timerId = setInterval(() => {
                setTimer((prev) => prev - 1);
            }, 1000);
        }
        return () => clearInterval(timerId);
    }, [isMeetingStarted, timer]);

    const handleStartMeeting = () => {
        setIsMeetingStarted(true);
    };

    const handleMute = () => {
        setIsMuted((prev) => !prev);
    };

    const handleCameraToggle = () => {
        setIsCameraOff((prev) => !prev);
    };

    const handleShareScreen = () => {
        setIsSharingScreen(prev => !prev); // Toggle screen sharing
        // Additional logic to start/stop screen sharing
    };

    return (
        <>
            <Modal
                show={show}
                onHide={() => setShow(false)}
                dialogClassName="modal-90w"
                aria-labelledby="example-custom-modal-styling-title"
            >
                <Modal.Header style={{ backgroundColor: '#7caee2', padding: 0 }}>
                    <Navbar expand="lg" style={{ backgroundColor: '#7caee2', width: '100%' }}>
                        <Container fluid>
                            <div className='w-100 d-flex justify-content-between align-items-center'>
                                <div>
                                    <Navbar.Brand className='fs-5 fw-semibold' href="#home">AECCI Virtual B2B Forum</Navbar.Brand>
                                    <div className='fs-6'>October 25, 26 & 27</div>
                                </div>
                            </div>
                        </Container>
                    </Navbar>
                </Modal.Header>

                <Modal.Body>
                    <Container fluid className="bg-light p-3">
                        <Row>
                            <Col md={9}>
                                <Row>
                                    <Col md={6}>
                                        <div className="text-center mt-2">
                                            <h5>{data.companyName}</h5>
                                            <div><strong>Industry:</strong> {data.industry}</div>
                                            <div><strong>Purpose:</strong> {data.userMessage}</div>
                                        </div>
                                        <div style={{ height: '400px', backgroundColor: '#f0f0f0' }}>
                                            <video
                                                ref={videoRef}
                                                autoPlay
                                                style={{ width: '100%', height: '100%', objectFit: 'cover' }}
                                            ></video>
                                        </div>
                                        {/* Control Buttons for Left Video Only */}
                                        <div className="d-flex justify-content-center mt-2">
                                            <div
                                                onClick={handleMute}
                                                style={{ cursor: 'pointer', padding: '10px', display: 'flex', alignItems: 'center' }}
                                                className={`icon-container ${isMuted ? 'active' : ''}`}
                                            >
                                                <FaMicrophone size={24} color={isMuted ? 'red' : 'black'} />
                                            </div>
                                            <div
                                                onClick={handleCameraToggle}
                                                className={`mx-2 icon-container ${isCameraOff ? 'active' : ''}`}
                                                style={{ cursor: 'pointer', padding: '10px', display: 'flex', alignItems: 'center' }}
                                            >
                                                <FaVideo size={24} color={isCameraOff ? 'red' : 'black'} />
                                            </div>
                                            <div
                                                onClick={handleShareScreen}
                                                className={`icon-container ${isSharingScreen ? 'active' : ''}`}
                                                style={{ cursor: 'pointer', padding: '10px', display: 'flex', alignItems: 'center' }}
                                            >
                                                <FaShareAlt size={24} color={isSharingScreen ? 'red' : 'black'} />
                                            </div>
                                        </div>
                                    </Col>
                                    <Col md={6}>
                                        <div className="text-center mt-2 mb-4">
                                            <h5>AECCI Test Collaboration</h5>
                                            <div><strong>Collaboration No:</strong> AECCI/COLL/124</div>
                                            <div><strong>Country:</strong> United Kingdom</div>
                                        </div>
                                        <div style={{ height: '400px', backgroundColor: '#dcdcdc' }}>
                                        </div>
                                        {/* No buttons for Right Video */}
                                    </Col>
                                </Row>
                                {/* Start Session Button */}
                                <div className="d-flex justify-content-center gap-4 mt-3">
                                    <Button
                                        size="sm"
                                        onClick={handleStartMeeting}
                                        disabled={isMeetingStarted}
                                    >
                                        Start Session
                                    </Button>
                                    <Button
                                    size="sm"
                                    variant='danger'
                                        onClick={handleStartMeeting}
                                        disabled={isMeetingStarted}
                                    >
                                        End Session
                                    </Button>
                                </div>
                            </Col>
                            <Col md={3}>
                                {/* Summary Box */}
                                <div style={{ height: '300px', backgroundColor: '#f8f9fa', border: '1px solid #ccc', borderRadius: '8px', padding: '15px', boxShadow: '0 2px 5px rgba(0, 0, 0, 0.1)' }}>
                                    <h5 className="text-center mb-3" style={{ fontWeight: 'bold', color: '#343a40' }}>Summary</h5>
                                    <div style={{ maxHeight: '200px', overflowY: 'auto' }}>
                                        <div className="mb-2">
                                            <p style={{ marginBottom: '5px', fontWeight: '500' }}>
                                                <span style={{ fontWeight: 'bold' }}>Status:</span> <FaCheckCircle className="text-success" /> <span>Online</span>
                                            </p>
                                            <p style={{ marginBottom: '5px', fontWeight: '500' }}>
                                                <span style={{ fontWeight: 'bold' }}>Remaining Time:</span> {Math.floor(timer / 60)}:{('0' + (timer % 60)).slice(-2)}
                                            </p>
                                        </div>
                                        {/* Add a divider line */}
                                        <hr />
                                        {/* You can add more messages here to simulate a chat interface */}
                                       
                                    </div>

                                    {/* Chat Input Area */}
                                    <div className="mt-3">
                                        <textarea
                                            rows={3}
                                            className="form-control"
                                            placeholder="Type your message..."
                                            style={{ resize: 'none' }}
                                        />
                                        <button className="btn btn-primary mt-2" style={{ width: '100%' }}>
                                            Send
                                        </button>
                                    </div>
                                </div>
                            </Col>

                        </Row>
                    </Container>
                </Modal.Body>
            </Modal>
        </>
    );
};

export default VideoConfrancing;

