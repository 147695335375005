export const domain = "https://e-platapi.aecci.org.in";
// export const domain = "http://localhost:3001";

// Hello;
const endpoints = {
  userAuth: "/user-auth",
  getNewsLetters: "/getNewsLetters",

  // registration ap
  createClient: "/createClient",
  checkEmail: "/checkEmail/",
  uploadDocCheckEmail: "/uploadDocCheckEmail/",
  uploadDocumentRegistration: "/uploadDocumentRegistration/",
  collaborationRegister: "/collaborationRegister/",
  loginClient: "/loginClient/",
  forgetPasswordSendOtp: "/forgetPasswordSendOtp/",
  forgetPassword: "/forgetPassword/",
  createTrialClient: "/createTrialClient/",

  // b2b connect request form
  createB2BEventRequest: "/b2b/createB2BEventRequest/",
  createParticipate: "/b2b/createParticipate/",
  getB2bEventRequest: "/b2b/getB2bEventRequest/",
  getInvitationLetter: "/b2b/getInvitationLetter/",
  processB2bPayment: "/b2b/processB2bPayment/",


  //b2b participates apis
  getParticipateList: "/b2b/getParticipateList/", 

  //b2b bookings api
  checkSlotAvailability: "/b2b/checkSlotAvailability/",
  getAvailableTimeSlots: "/b2b/getAvailableTimeSlots/",
  checkCountryAvailability: "/b2b/checkCountryAvailability/",
  checkSlotTimeAvailability: "/b2b/checkSlotTimeAvailability/",
  checkSingleCountryAvailability: "/b2b/checkSingleCountryAvailability/",
  checkCountrySelection: "/b2b/checkCountrySelection/",
  checkDateAvailability: "/b2b/checkDateAvailability/",
  bookSlot: "/b2b/bookSlot/",
  addB2bFundToWallet: "/addB2bFundToWallet/",



  // profile page apis
  updatePersonalDetalis: "/updatePersonalDetalis/",
  updateCompanyDetails: "/updateCompanyDetails/",
  marketingDetails: "/marketingDetails/",
  changePassword: "/changePassword/",
  updateSocialMedia: "/updateSocialMedia/",

  //Services Apis
  getComDirDetails: "/getComDirDetails/",
  commercialDir: "/commercialDir/",
  clientB2BColloboration: "/clientB2BColloboration/",
  createRecommendationLetter: "/createRecommendationLetter/",
  addFundToWallet: "/addFundToWallet/",
  getWalletHistory: "/getWalletHistory/",
  renewMembership: "/renewMembership/",
  refreshBal: "/refreshBal/",
  //eco apis
  createEco: "/createEco/",
  createMco: "/createMco/",
  getUserEcoData: "/getUserEcoData/",
  getUserMcoData: "/getUserMcoData/",
  createNonStandard: "/createNonStandard/",
  getNonStandDocUser: "/getNonStandDocUser/",
  getStamppedNonStandDocUser: "/getStamppedNonStandDocUser/",
  downDocNonStand: "/downDocNonStand/",

  //Draft Apis
  createEcoDraft: "/createEcoDraft/",
  getAllDraft: "/getAllDraft/",
  deleteDraft: "/deleteDraft/",
  updateDraft: "/updateDraft/",

  //req for upgrade
  upgradeMembership: "/upgradeMembership/",

  // all get req
  getpersonalinfo: "/getpersonalinfo/",
  getCompanyDetails: "/getCompanyDetails/",
  getVisaRecommendationData: "/getVisaRecommendationData/",

  //THE WING APIs--------------------
  createQuery: "/wing/createQuery/",
  getWingDataUser: "/wing/getWingDataUser/",
  getAllMessagesWings: "/wing/getAllMessages/",
  submitReview: "/wing/submitReview/",

  //legal wing apis
  createLegalWingRequest: "/wing/createLegalWingRequest/",

  //Export wing apis
  createExportWingRequest: "/wing/createExportWingRequest/",

  //Professional wing apis
  createProfessionalWingRequest: "/wing/createProfessionalWingRequest/",

  //Hr wing apis
  createHrWingRequest: "/wing/createHrWingRequest/",

  //Business wing apis
  createBusinessWingRequest: "/wing/createBusinessWingRequest/",
  
  //Event wing apis
  createEventWingRequest: "/wing/createEventWingRequest/",

  //arbitration apis
  createNonBindingDispute: "/arbitration/createNonBindingDispute/",
  getNonBindingDispute: "/arbitration/getNonBindingDispute/",
  nonBindingPaymentProcess: "/arbitration/nonBindingPaymentProcess/",
  updateDisputeDocMessage: "/arbitration/updateDisputeDocMessage/",

  //publication apis
  getPublication: "/getPublication/",

  //ad booking Apis
  createAdBooking: "/ads/createAdBooking/",
  getAdBooking: "/ads/getAdBooking/",
  getAllAds: "/ads/getAllAds/",
  getAllApprovedAdsData: "/ads/getAllApprovedAdsData/",

  //Event apis
  getEvents: "/getEvents/",
  registerEvent: "/registerEvent/",
  getRegisterEvent: "/getRegisterEvent/",

  //independent pages apis
  getVerMembCertStatus: "/getVerMembCertStatus/",
  getVerVisaStatus: "/getVerVisaStatus/",
  getCertOfOrigin: "/getCertOfOrigin/",
  checkEmailExists: "/checkEmailExists/",
};

function getURLbyEndPoint(endpoint) {
  return domain + endpoints[endpoint];
}

function getURLbyEndPointV2(endpoint) {
  return domain + "/api/v2" + endpoints[endpoint];
}

export { endpoints, getURLbyEndPoint, getURLbyEndPointV2 };

