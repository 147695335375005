import React, { useEffect, useState } from "react";
import Layout2 from "../../../components/Layouts/Layouts/Layout2";
import DashboardHeader from "../../../components/basic/DashboardHeader";
import Layout from "../../../components/Layouts/Layouts/Layout";
import { Badge, Button, Card, Col, Modal, Row } from "react-bootstrap";
import "./b2b.css";
import { getAuthUserlocal } from "../../../store/services";
import axios from "axios";
import { getURLbyEndPointV2 } from "../../../store/api";
import { useLocation } from "react-router";
import VideoConfrancing from "../../../components/b2bPricing/VIdeoConfrancing";

const B2bInterestedClient = () => {
  const [participantList, setParticipateList] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [selectedParticipant, setSelectedParticipant] = useState(null);
  const location = useLocation();

  useEffect(() => {
    if (location.state) {
      setSelectedParticipant(location.state.participant);
    }
  }, [location.state]);

  const handleClose = () => {
    setShowModal(false);
    setSelectedParticipant(null);
  };

  const colors = [
    "#FFF9F9", // Light pink
    "#F5FFFF", // Light cyan
    "#FFF5E1", // Light peach
    "#F0FFF0", // Honeydew (very light green)
    "#FFF0F5", // Lavender blush
    "#F0F8FF", // Alice blue
    "#F5F5DC", // Beige
    "#F5FFFA", // Mint cream
    "#FAFAD2", // Light goldenrod yellow
    "#FFFFF0", // Ivory
    "#FDF5E6", // Old lace (light beige)
    "#F0FFFF", // Azure (very light cyan)
  ];

  const getRandomColor = () => {
    return colors[Math.floor(Math.random() * colors.length)];
  };

  const fetchParticipateList = async () => {
    try {
      const userId = getAuthUserlocal()._id;
      const country = getAuthUserlocal().country;

      const response = await axios.get(
        getURLbyEndPointV2("getParticipateList") + userId
      );
      if (response.status === 200) {
        const data = response.data;
        setParticipateList(data.data);

        if (location.state && location.state.participant) {
          const bookedCountries = location.state.participant.bookedCountries;

          console.log("apicall", data.data);
          console.log("apicall location", bookedCountries);

          // Iterate over `bookedCountries` and check if any of them match `clientId` in the API data
          const matchedParticipant = data.data.find((participant) =>
            bookedCountries.some((country) => country.clientId === participant.clientId)
          );

          console.log("matched", matchedParticipant);

          if (matchedParticipant) {
            setSelectedParticipant(matchedParticipant);
            setShowModal(true); // Open the modal if a match is found
          } else {
            console.warn("No matching participant found");
          }
        }
      } else {
        console.error("Failed to fetch participant list");
      }
    } catch (error) {
      console.error("Error fetching Event data:", error);
    }
  };

  useEffect(() => {
    fetchParticipateList();
  }, []);

  return (
    <>
      <Layout>
        <Layout2>
          <DashboardHeader />
          <div className="">
            <div>
              <div className="d-flex align-items-center">
                <h4 className="py-4">INTERESTED PARTICIPANTS LIST</h4>
              </div>
              <hr className="mt-1 mb-1" />
            </div>
            <div style={{ backgroundColor: "#F4F4F9" }} className="p-3 rounded">
              <h3 className="text-center text-bold">AECCI Virtual B2B FORUM</h3>
              <hr className="mx-auto w-75" />
              <div>
                <p className="mt-3">
                  We are pleased to provide a list of registered participants: individuals,
                  manufacturers, exporters, and importers. This list is based on
                  the registrations received and approved by our membership desk.
                  Please note that the list is realistic but tentative and may
                  not reflect the exact number of interested participants from
                  your country.
                </p>
                <p>
                  <strong>Daily Meeting Slots Available:</strong> <br />
                  Minimum: 5 business slots <br />
                  Maximum: 6 business slots<br />
                  (Maximum 5 hours a day-each delegate)<br />
                </p>
                <p>
                  This allocation ensures that each country has a fair opportunity
                  to engage in business meetings.
                </p>
                <p className="text-muted">
                  Note: The number of available slots may vary based on daily
                  registrations and approvals.
                </p>
              </div>
              <div className="my-2 fs-6 d-flex flex-row gap-2 w-100">
                <Button className="flex-grow-1">25 Oct</Button>
                <Button className="flex-grow-1" disabled>
                  26 Oct
                </Button>
                <Button className="flex-grow-1" disabled>
                  27 Oct
                </Button>
              </div>

              <div className="mt-3">
                <div className="masonry-grid">
                  {participantList.map((item, index) => (
                    <div key={index} className="masonry-item">
                      <Card
                        style={{
                          backgroundColor: getRandomColor(),
                          borderRadius: "10px",
                          boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                          cursor: "pointer",
                        }}
                      >
                        <Card.Body>
                          <Card.Title className="fs-5 fw-bold text-center">
                            {item.companyName
                              ? item.companyName
                              : `${item.name} (${item.profession})`}
                          </Card.Title>

                          <Card.Text className="text-center my-2">
                            <Badge bg="primary" className="px-3 py-1">
                              {item.entity}
                            </Badge>
                          </Card.Text>

                          <Card.Text className="text-center my-3">
                            <span className="fw-semibold">Purpose: </span>
                            {item.userMessage}
                          </Card.Text>

                          <Card.Text className="text-center text-muted text-xs">
                            Slot Not yet confirmed
                          </Card.Text>
                        </Card.Body>
                      </Card>
                    </div>
                  ))}
                </div>

                {/* Modal to show the selected participant details */}
                {selectedParticipant && (
                  <VideoConfrancing data={selectedParticipant}/>
                  // <Modal show={showModal} onHide={handleClose}>
                  //   <Modal.Header closeButton>
                  //     <Modal.Title>Participant Details</Modal.Title>
                  //   </Modal.Header>
                  //   <Modal.Body>
                  //     <p><strong>Company Name:</strong> {selectedParticipant.companyName}</p>
                  //     <p><strong>Time Slot:</strong> {location.state.timeSlot}</p>
                  //     <p><strong>Email:</strong> {selectedParticipant.email}</p>
                  //     <p><strong>Representatives:</strong></p>
                  //     {selectedParticipant.represent?.map((rep, idx) => (
                  //       <p key={idx}>{rep.firstName} {rep.lastName} ({rep.designation})</p>
                  //     ))}
                  //     <p><strong>Business License:</strong> <a href={selectedParticipant.businessLicense}>View Document</a></p>
                  //   </Modal.Body>
                  //   <Modal.Footer>
                  //     <Button variant="secondary" onClick={handleClose}>
                  //       Close
                  //     </Button>
                  //   </Modal.Footer>
                  // </Modal>
                )}
              </div>
            </div>
          </div>
        </Layout2>
      </Layout>
    </>
  );
};

export default B2bInterestedClient;
