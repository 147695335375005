import axios from "axios";
import React, { useEffect, useState } from "react";
import { Button, Card, CloseButton, Col, Dropdown, DropdownButton, Modal, Nav, Row, Table } from "react-bootstrap";
import { IoMdAdd } from "react-icons/io";
import { MdDelete, MdModeEdit, MdOutlineNavigateBefore, MdOutlineNavigateNext, } from "react-icons/md";
import b2bVirtualForum from "../../assets/b2bImages/b2bVirtualForum.png";
import { usePricing } from "../../context/PricingProvider";
import { collaboration_countrys } from "../../pages/auth/Register/AllRegiPageLists";
import { getURLbyEndPointV2 } from "../../store/api";
import { getAuthUserlocal } from "../../store/services";

const PricingModal = ({ }) => {
  const [show, setShow] = useState(false);
  const [currentStep, setCurrentStep] = useState(0);
  const { pricingData, updatePricingData } = usePricing();
  const [isBookSlotValid, setIsBookSlotValid] = useState(false);
  const [loading, setLoading] = useState(false)

  const handleShow = () => {
    setShow(true);
  }

  const handleClose = () => {
    setShow(false);
    setCurrentStep(0);
  };

  const validateBookSlotStep = () => {
    const { slots } = pricingData;
    return slots.every((slot) =>
      slot.country &&
      slot.time &&
      !slot.countryError &&
      !slot.slotError
    );
  };

  const handleNext = async () => {
    if (currentStep < steps.length - 1) {
      if (currentStep === 3 && !validateBookSlotStep()) {
        alert("Please fill all fields correctly before proceeding.");
        return;
      }
      setCurrentStep(currentStep + 1);
    } else {
      await handleSubmit();
    }
  };


  const handlePrevious = () => {
    if (currentStep > 0) {
      setCurrentStep(currentStep - 1);
    }
  };


  const handleBookSlotUpdate = (updatedData, isValid) => {
    updatePricingData(updatedData);
    setIsBookSlotValid(isValid);
  };

  const steps = [
    {
      key: "general-info",
      title: "General Information",
      content: <GeneralInformation data={pricingData} onUpdate={updatePricingData} />,
    },
    {
      key: "agenda",
      title: "Agenda",
      content: <Agenda data={pricingData} onUpdate={updatePricingData} />,
    },
    {
      key: "terms-conditions",
      title: "Terms & Conditions",
      content: <TermsConditions data={pricingData} onUpdate={updatePricingData} />,
    },
    {
      key: "book-slot",
      title: "Book Your Slot",
      content: <BookSlot data={pricingData} onUpdate={handleBookSlotUpdate} />,
    },
    {
      key: "review",
      title: "Review",
      content: <Review data={pricingData} />,
    },
  ];

  const handleSubmit = async () => {
    const userId = getAuthUserlocal()._id;
    setLoading(true);
    try {
      const response = await axios.post(getURLbyEndPointV2("bookSlot") + userId, {
        bookings: pricingData,
      });
      alert(response.data.message);
      handleClose();
      window.location.reload()
    } catch (error) {
      console.error("Error saving booking:", error);

      alert(error.response?.data?.message);
    } finally {
      setLoading(false);
    }
  };
  return (
    <>
      <Button variant="primary" size="sm" className="me-2" onClick={handleShow}>
        Book Slot
      </Button>
      <Modal
        show={show}
        onHide={handleClose}
        centered
        backdrop="static"
        size="xl"
        className="rounded h-100"
      >
        <Modal.Body className="p-0">
          <div>
            <Modal.Title className="w-100 text-center">
              <img
                src={b2bVirtualForum}
                className="img-fluid"
                style={{ width: "100px" }}
                alt="b2b logo"
              />
              <span className="fw-bold fs-2">AECCI Virtual B2B Forum</span>
            </Modal.Title>
            <CloseButton
              variant="danger"
              className="position-absolute top-0 fs-5 end-0 mt-4 me-4"
              onClick={handleClose}
            />
          </div>
          <hr className="mt-2 mb-0" />
          <Row className="m-0 h-100">
            <Col md={3} className="d-flex flex-column h-100 bg-light">
              <div
                className="d-flex my-4 flex-column w-100 overflow-auto"
                style={{ maxHeight: "calc(100vh - 100px)" }}
              >
                <Nav
                  className="flex-column gap-4"
                  variant="pills"
                  activeKey={steps[currentStep].key}
                  onSelect={(selectedKey) => {
                    const stepIndex = steps.findIndex(
                      (step) => step.key === selectedKey
                    );
                    if (stepIndex <= currentStep) {
                      setCurrentStep(stepIndex);
                    }
                  }}
                >
                  {steps.map((step, index) => (
                    <Nav.Item key={step.key}>
                      <Nav.Link
                        eventKey={step.key}
                        disabled={index > currentStep}
                      >
                        {step.title}
                      </Nav.Link>
                    </Nav.Item>
                  ))}
                </Nav>
              </div>
            </Col>
            <Col md={9}>
              <Card className="my-3">
                <Card.Header>
                  <div className="fs-5 fw-bold">{steps[currentStep].title}</div>
                </Card.Header>
                <Card.Body className="px-4">
                  {steps[currentStep].content}
                  <div className="py-2 d-flex justify-content-between">
                    <Button
                      variant="secondary"
                      onClick={handlePrevious}
                      disabled={currentStep === 0}
                      className="me-2"
                      size="sm"
                    >
                      <MdOutlineNavigateBefore className="fs-4" />
                      Previous
                    </Button>
                    <Button
                      variant="primary"
                      onClick={handleNext}
                      size="sm"
                      disabled={currentStep === 3 && !validateBookSlotStep()}
                    >
                      {loading ? (
                        <>
                          <span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                          <span className="visually-hidden">Loading...</span>
                        </>
                      ) : (
                        <>
                          {currentStep === steps.length - 1 ? "Submit" : <>Next <MdOutlineNavigateNext className="fs-4" /></>}
                        </>
                      )}
                    </Button>
                  </div>
                </Card.Body>
              </Card>
            </Col>
          </Row>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default PricingModal;

const GeneralInformation = ({ data, onUpdate }) => {
  const createdAt = new Date(data.createdAt);

  const formattedDate = createdAt.toLocaleDateString("en-US", {
    day: "numeric",
    month: "long",
    year: "numeric",
  });

  const formattedTime = createdAt.toLocaleTimeString("en-US", {
    hour: "2-digit",
    minute: "2-digit",
    hour12: true,
  });

  return (
    <div className="ps-4 my-1">
      <Row className="mb-2">
        <Col sm={4} className="fw-bold">
          Company Name:
        </Col>
        <Col sm={8}>{data.companyName}</Col>
      </Row>
      <Row className="mb-2">
        <Col sm={4} className="fw-bold">
          Full Name:
        </Col>
        <Col sm={8}>{data.name}</Col>
      </Row>
      <Row className="mb-2">
        <Col sm={4} className="fw-bold">
          Phone:
        </Col>
        <Col sm={8}>{data.phoneNo}</Col>
      </Row>
      <Row className="mb-2">
        <Col sm={4} className="fw-bold">
          Email:
        </Col>
        <Col sm={8}>{data.email}</Col>
      </Row>
      <Row className="mb-2">
        <Col sm={4} className="fw-bold">
          Industry:
        </Col>
        <Col sm={8}>{data.industry}</Col>
      </Row>
      <Row className="mb-2">
        <Col sm={4} className="fw-bold">
          Interested Countries:
        </Col>
        <Col sm={8}>{data.country.join(", ")}</Col>
      </Row>
      <Row className="mb-2">
        <Col sm={4} className="fw-bold">
          Total Countries:
        </Col>
        <Col sm={8}>{data.country.length}</Col>
      </Row>
      <Row className="mb-2">
        <Col sm={4} className="fw-bold">
          Date of Inquiry:
        </Col>
        <Col sm={8}>{formattedDate} | {formattedTime}</Col>
      </Row>
      <Row className="mb-2">
        <Col sm={4} className="fw-bold">
          Purpose:
        </Col>
        <Col sm={8}>{data.userMessage}</Col>
      </Row>
    </div>
  );
};

const Agenda = ({ data, onUpdate }) => {
  return (
    <div className="my-1">
      <Row className='mb-3'>
        <Col md={5}>
          <div className="fs-6">
            <strong>Event :</strong> AECCI Virtual B2B Forum</div>
          <div className="fs-6"><strong>Date :</strong> 25th, 26th &27th October, 2024</div>
          <div className="fs-6"><strong>Platform :</strong> AECCI e-Platform</div>
          <div className="fs-6"><strong>Mode :</strong> Video Conferencing</div>
        </Col>
        <Col md={7}>
          <div className="fs-6"><strong>Interaction Type :</strong> One-to-one Slot</div>
          <div className="fs-6"><strong>Duration :</strong> 30 Minutes each slot</div>
          <div className="fs-6"><strong>Break :</strong> 15 Minutes break after every slot.</div>
          <div className="fs-6"><strong>Participants each slot :</strong> 2 (1 Foreign Delegate + 1 Indian Business)</div>
        </Col>
      </Row>
      <hr />
      <Row>
        <Col>
          <Table striped bordered hover>
            <thead
            >
              <tr>
                <th>Sr. No.</th>
                <th colSpan={2}>Slots for “One-on-one Discussion”
                  (25th, 26th & 27th October, 2024)
                  Each Discussion: 1 Delegate + 1 Business Representative
                </th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>1</td>
                <td>10:00AM - 10:30AM</td>
                <td>(Slot Category 1)</td>
              </tr>
              <tr>
                <td>2</td>
                <td>10:45AM - 11:15AM</td>
                <td>(Slot Category 2) </td>
              </tr>
              <tr>
                <td>3</td>
                <td>11:30AM - 12:00PM</td>
                <td>(Slot Category 3)</td>
              </tr>
              <tr>
                <td>4</td>
                <td>12:15PM - 12:45PM</td>
                <td>(Slot Category 4)</td>
              </tr>
              <tr>
                <td>5</td>
                <td>01:00PM - 01:30PM</td>
                <td>(Slot Category 5)</td>
              </tr>
              <tr>
                <td colSpan={3} className="text-center">
                  Lunch Break
                </td>
              </tr>
              <tr>
                <td>6</td>
                <td>02:30PM - 03:00PM</td>
                <td>(Slot Category 6)</td>
              </tr>
              <tr>
                <td>7</td>
                <td>03:15PM - 03:45PM</td>
                <td>(Slot Category 7)</td>
              </tr>
              <tr>
                <td>8</td>
                <td>04:00PM - 04:30PM</td>
                <td>(Slot Category 8)</td>
              </tr>
              <tr>
                <td>9</td>
                <td>04:45PM - 05:15PM</td>
                <td>(Slot Category 9)</td>
              </tr>
              <tr>
                <td>10</td>
                <td>05:30PM - 06:00PM</td>
                <td>(Slot Category 10)</td>
              </tr>
            </tbody>
          </Table>
        </Col>
      </Row>
    </div>
  );
};


const TermsConditions = ({ data, onUpdate }) => (
  <>
    <h6 className="fw-bold mb-3">VBF - Booking OPEN</h6>
    <hr className="my-1" />
    <div className="p-2">
      <ul>
        <li>
          Virtual B2B Forum: format will be ‘One-To-One” interaction for 30 minutes each slot.
        </li>
      </ul>
    </div>
    <h6 className="fw-bold">
      Terms and Conditions of Participation:
    </h6>
    <hr className="my-1" />
    <div className="p-2">
      <ul>
        <li>
          Fees chargeable for VBF is ( Rs 2000 per Country ) per Slot.
        </li>
        <li>
          Maximum one representative per session, will be allowed. In case of 6 or more countries chosen, company may depute additional representative and slots can be divided in both of the representatives. Condition of ‘one representative at-a-time’ will remain applicable.
        </li>
        <li>
          All bookings are non-transferable. The registration is valid only for the individual participant or entity that made the booking.
        </li>
        <li>
          Carefully chose the countries while making the final booking and payment. Before making the payment you can edit, delete or add the countries according to your requirements.
        </li>
        <li>
          Fees for the booking confirmation will be paid through AECCI e-Platform and will be deducted from your wallet directly. Please ensure to maintain the minimum amount according to the number of chosen countries.
        </li>
        <li>
          Once your booking is confirmed, you will receive a confirmation email with your slot timing details. Please review this information carefully.
        </li>
        <li>
          Each business is suggested to adhere to the slot’s timings. Delayed entries will be treated cancelled.
        </li>
        <li>
          By proceeding with your booking, you acknowledge and agree to the above-given terms and conditions.
        </li>
      </ul>
    </div>
    <h6 className="fw-bold mt-2">Additional benefits:</h6>
    <hr className="my-1" />
    <div className="p-3 rounded-lg shadow-md bg-white">
      <ul className="space-y-4">
        <li className="text-lg font-semibold text-blue-600 flex items-center">
          Companies opting for <strong>6 or more countries</strong> will get <span className="text-blue-700">One-year complimentary “Associate Membership”</span>.
        </li>
        <li className="text-lg font-semibold text-green-600 flex items-center">
          Companies opting for <strong>4 or more countries</strong> will get <span className="text-green-700">one-year complimentary “e-Platform”</span>.
        </li>
        <li className="text-lg font-semibold text-purple-600 flex items-center">
          Companies opting for <strong>2 or more countries</strong> will get <span className="text-purple-700">6-months complimentary access to “e-Platform”</span>.
        </li>
      </ul>
      <div className='text-sm text-gray-500 mt-4 font-medium'>*T&C Apply</div>
    </div>

  </>
);

const BookSlot = ({ data, onUpdate }) => {
  const userId = getAuthUserlocal()._id;
  const [warningMessage, setWarningMessage] = useState("");
  const [slots, setSlots] = useState([]);
  const [availableCountries, setAvailableCountries] = useState([]);
  const [availableTimeSlots, setAvailableTimeSlots] = useState([]);
  const [selectedDay, setSelectedDay] = useState("");
  const maxCountries = 6;

  const timeSlots = [
    "10:00AM - 10:30AM",
    "10:45AM - 11:15AM",
    "11:30AM - 12:00PM",
    "12:15PM - 12:45PM",
    "01:00PM - 01:30PM",
    "02:30PM - 03:00PM",
    "03:15PM - 03:45PM",
    "04:00PM - 04:30PM",
    "04:45PM - 05:15PM",
    "05:30PM - 06:00PM",
  ];

  const getDateBySelectedDay = (day) => {
    switch (day) {
      case "day1":
        return "2024-10-25";
      case "day2":
        return "2024-10-26";
      case "day3":
        return "2024-10-27";
      default:
        return "";
    }
  };

  useEffect(() => {
    const fetchAvailableCountries = async () => {
      try {
        const response = await axios.post(getURLbyEndPointV2("checkCountryAvailability") + userId);
        setAvailableCountries(response.data.availableCountries || []);
      } catch (error) {
        console.error("Error fetching available countries:", error);
      }
    };
    fetchAvailableCountries();
  }, [userId]);


  const fetchAvailableTimeSlots = async (country, date) => {
    try {
      const response = await axios.post(
        getURLbyEndPointV2("getAvailableTimeSlots") + userId,
        { country, date }
      );

      console.log(response.data.availableTimeSlots)
      setAvailableTimeSlots(response.data.availableTimeSlots || []);
    } catch (error) {
      console.error("Error fetching available time slots:", error);
      setAvailableTimeSlots([]);
    }
  };


  const checkCountryAvailability = async (countries) => {
    try {
      const response = await axios.post(
        getURLbyEndPointV2("checkCountryAvailability") + userId,
        { countries }
      );
      return response.data.availableCountries;
    } catch (error) {
      console.error("Error checking countries:", error);
      return [];
    }
  };

  const checkInitialCountryAvailability = async (countries, slotsToUpdate) => {
    const availableCountries = await checkCountryAvailability(countries);

    const updatedSlots = slotsToUpdate.map((slot) => {
      if (availableCountries.includes(slot.country)) {
        return { ...slot, countryError: "" };
      } else {
        return {
          ...slot,
          countryError: `The selected country '${slot.country}' is not available.`,
        };
      }
    });

    setSlots(updatedSlots);
    updateSlots(updatedSlots);
  };

  useEffect(() => {
    if (data && Array.isArray(data.country) && data.country.length > 0) {
      const initialSlots = data.country.map((c) => ({
        country: c,
        duration: "30 min",
        selectedDay: "",
        date: getDateBySelectedDay(""),
        time: "",
        price: 2000,
        slotError: "",
        countryError: "",
        isEditing: false,
      }));

      setSlots(initialSlots);
      const uniqueCountries = Array.from(new Set(data.country));
      checkInitialCountryAvailability(uniqueCountries, initialSlots);
    }
  }, []);

  const calculateTotalAmount = (slots) => {
    return slots.reduce((total, slot) => total + slot.price, 0);
  };

  const updateSlots = (updatedSlots) => {
    const totalAmount = updatedSlots.reduce((total, slot) => total + slot.price, 0);
    const updatedData = { ...data, slots: updatedSlots, totalAmount };
    const hasErrors = updatedSlots.some((slot) => slot.countryError || slot.slotError);
    onUpdate(updatedData, !hasErrors);
  };

  const handleDayChange = async (index, day) => {
    const selectedCountry = slots[index].country;
    const selectedDate = getDateBySelectedDay(day);
    await fetchAvailableTimeSlots(selectedCountry, selectedDate); // Fetch available time slots
    const updatedSlots = slots.map((slot, idx) =>
      idx === index ? { ...slot, selectedDay: day, date: selectedDate, time: "", slotError: "" } : slot
    );
    setSlots(updatedSlots);
    updateSlots(updatedSlots);
  };




  const handleDurationChange = (index, selectedDuration) => {
    const updatedSlots = slots.map((slot, idx) =>
      idx === index ? { ...slot, duration: selectedDuration } : slot
    );
    setSlots(updatedSlots);
    updateSlots(updatedSlots);
  };
  //   const selectedCountry = slots[index].country;
  //   const selectedDate = slots[index].date;
  //   const [startTime] = selectedTime.split(" - ");

  //   try {
  //     const response = await axios.post(
  //       getURLbyEndPointV2("checkSlotTimeAvailability") + userId,
  //       { country: selectedCountry, time: startTime, date: selectedDate }
  //     );

  //     // If the time slot is available, update the slot with the selected time and clear any errors.
  //     const updatedSlots = slots.map((slot, idx) =>
  //       idx === index ? { ...slot, time: selectedTime, slotError: "" } : slot
  //     );
  //     setSlots(updatedSlots);
  //     updateSlots(updatedSlots);
  //   } catch (err) {
  //     // If there's an error (e.g., time not available), clear the selected time and show an error message.
  //     const updatedSlots = slots.map((slot, idx) =>
  //       idx === index ? { ...slot, time: "", slotError: "This time slot is not available. Please choose another." } : slot
  //     );
  //     setSlots(updatedSlots);
  //     updateSlots(updatedSlots);
  //   }
  // };

  const handleCountryChange = async (index, selectedCountry) => {
    const isCountryAlreadySelected = slots.some((slot, idx) => idx !== index && slot.country === selectedCountry);

    if (isCountryAlreadySelected) {
      const updatedSlots = slots.map((slot, idx) =>
        idx === index
          ? { ...slot, countryError: `The country '${selectedCountry}' is already selected.` }
          : slot
      );
      setSlots(updatedSlots);
      updateSlots(updatedSlots);
      return;
    }

    const updatedSlots = slots.map((slot, idx) =>
      idx === index
        ? {
          ...slot,
          country: selectedCountry,
          time: "",
          date: "",
          selectedDay: "",
          countryError: "",
          isEditing: false
        }
        : slot
    );

    setSlots(updatedSlots);
    updateSlots(updatedSlots);

    try {
      const response = await axios.post(
        getURLbyEndPointV2("checkSingleCountryAvailability") + userId,
        { country: selectedCountry }
      );

      if (response.data.message === "This country is available.") {
        const clearedErrorSlots = updatedSlots.map((slot, idx) =>
          idx === index ? { ...slot, countryError: "" } : slot
        );
        setSlots(clearedErrorSlots);
        updateSlots(clearedErrorSlots);
      } else {
        const updatedSlotsWithError = updatedSlots.map((slot, idx) =>
          idx === index
            ? { ...slot, countryError: `The country '${selectedCountry}' is not available.` }
            : slot
        );
        setSlots(updatedSlotsWithError);
        updateSlots(updatedSlotsWithError);
      }
    } catch (err) {
      const updatedSlotsWithError = updatedSlots.map((slot, idx) =>
        idx === index
          ? { ...slot, countryError: err.response?.data?.message || "Error checking availability." }
          : slot
      );
      setSlots(updatedSlotsWithError);
      updateSlots(updatedSlotsWithError);
    }
  };

  const handleTimeChange = async (index, selectedTime) => {
    const selectedCountry = slots[index].country;
    const selectedDate = slots[index].date;
    const [startTime] = selectedTime.split(" - ");
    try {
      const response = await axios.post(
        getURLbyEndPointV2("checkSlotTimeAvailability") + userId,
        { country: selectedCountry, time: startTime, date: selectedDate }
      );
      const updatedSlots = slots.map((slot, idx) =>
        idx === index ? { ...slot, time: selectedTime, slotError: "" } : slot
      );
      setSlots(updatedSlots);
      updateSlots(updatedSlots);
    } catch (err) {
      const errorMessage = err.response?.data?.message || "This time slot is not available. Please choose another.";

      const updatedSlots = slots.map((slot, idx) =>
        idx === index ? { ...slot, time: "", slotError: errorMessage } : slot
      );
      setSlots(updatedSlots);
      updateSlots(updatedSlots);
    }
  };


  const handleAddRow = () => {
    if (slots.length < maxCountries) {
      const newSlot = {
        country: "",
        duration: "30 min",
        date: getDateBySelectedDay(selectedDay),
        time: "",
        price: 2000,
        slotError: "",
        countryError: "",
        isEditing: true,
      };
      const updatedSlots = [...slots, newSlot];
      setSlots(updatedSlots);
      updateSlots(updatedSlots);
      setWarningMessage("");
    } else {
      setWarningMessage(`Maximum ${maxCountries} countries can be added.`);
    }
  };

  const toggleEdit = (index) => {
    const updatedSlots = slots.map((slot, idx) =>
      idx === index ? { ...slot, isEditing: !slot.isEditing } : slot
    );
    setSlots(updatedSlots);
    updateSlots(updatedSlots);
  };

  const handleDelete = (index) => {
    const updatedSlots = slots.filter((_, idx) => idx !== index);
    setSlots(updatedSlots);
    updateSlots(updatedSlots);
    setWarningMessage("");
  };





  return (
    <div>
      <Table striped bordered hover>
        <thead>
          <tr>
            <th>Sr</th>
            <th>List Of Country</th>
            <th>Duration</th>
            <th>Day</th>
            <th>Slot Time</th>
            <th>Pricing</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {slots.map((slot, index) => (
            <tr key={index}>
              <td>{index + 1}</td>
              <td>
                <div style={{ width: '150px', maxWidth: '150px' }}>
                  {slot.isEditing ? (
                    <DropdownButton
                      id={`dropdown-country-${index}`}
                      title={slot.country || "Select Country"}
                      size="sm"
                      onSelect={(selectedCountry) => handleCountryChange(index, selectedCountry)}
                    >
                      <div style={{ maxHeight: "200px", overflowY: "auto" }}>
                        {collaboration_countrys.map((c, idx) => {
                          // Determine if the country is available
                          const isAvailable = availableCountries.includes(c);
                          return (
                            <Dropdown.Item
                              key={idx}
                              eventKey={c}
                              disabled={!isAvailable} // Disable if not available
                              style={{
                                backgroundColor: isAvailable ? 'white' : '#F1F0E8', // Use #F1F0E8 for unavailable
                                color: isAvailable ? 'black' : 'darkgray', // Set text color for better visibility
                              }}
                            >
                              {c}
                            </Dropdown.Item>
                          );
                        })}
                      </div>
                    </DropdownButton>
                  ) : (
                    slot.country
                  )}
                  {slot.countryError && (
                    <div className="text-danger" style={{ fontSize: "0.8rem" }}>
                      {slot.countryError}
                    </div>
                  )}
                </div>
              </td>
              <td>
                <select
                  className="form-control rounded-0"
                  value={slot.duration || "30 min"}
                  onChange={(e) => handleDurationChange(index, e.target.value)}
                  disabled
                >
                  <option value="30 min">30 min</option>
                </select>
              </td>
              <td>
                <select
                  className="form-control rounded-0"
                  value={slot.selectedDay || ""}  // Set the default value to "selectDay"
                  onChange={(e) => handleDayChange(index, e.target.value)}
                >
                  <option value="" disabled style={{ backgroundColor: '#F1F0E8' }}>
                    Select Day
                  </option>
                  <option value="day1">25th Oct</option>
                  <option value="day2">26th Oct</option>
                  <option value="day3">27th Oct</option>
                </select>
              </td>
              <td>
                <select
                  className={`form-control rounded-0 ${slot.slotError ? 'is-invalid' : ''}`}
                  value={slot.time || ""}
                  onChange={(e) => handleTimeChange(index, e.target.value)}
                  style={{ backgroundColor: 'white' }} // Default background for the select
                >
                  <option value="" disabled style={{ backgroundColor: '#F1F0E8' }}>
                    Select Time Slot
                  </option>
                  {timeSlots.map((time, idx) => (
                    <option
                      key={idx}
                      value={time}
                      disabled={!availableTimeSlots.includes(time)}
                      style={{
                        backgroundColor: !availableTimeSlots.includes(time) ? '#F1F0E8' : 'white', // Use #F1F0E8 for disabled options
                        color: !availableTimeSlots.includes(time) ? 'gray' : 'black', // Gray text for disabled options
                      }}
                    >
                      {time}
                    </option>
                  ))}
                </select>

                {/* Show error message below the select input if there's an error */}
                {slot.slotError && (
                  <div className="text-danger" style={{ fontSize: "0.7rem" }}>
                    {slot.slotError}
                  </div>
                )}
              </td>




              <td>{slot.price}/-</td>
              <td>
                <div className="d-flex gap-1">
                  {index === slots.length - 1 &&
                    slots.length < maxCountries && (
                      <Button
                        variant="primary"
                        size="sm"
                        className="me-2"
                        onClick={handleAddRow}
                      >
                        <IoMdAdd />
                      </Button>
                    )}
                  <Button
                    variant={slot.isEditing ? "success" : "warning"}
                    size="sm"
                    className="me-2"
                    onClick={() => toggleEdit(index)}
                  >
                    {slot.isEditing ? "Save" : <MdModeEdit />}
                  </Button>
                  {slots.length > 1 && ( // Conditionally render the delete button
                    <Button
                      variant="danger"
                      size="sm"
                      className="me-2"
                      onClick={() => handleDelete(index)}
                    >
                      <MdDelete />
                    </Button>
                  )}
                </div>
              </td>
            </tr>
          ))}
        </tbody>
        <tfoot>
          <tr>
            <td colSpan="5" className="text-start fw-bold">
              Total
            </td>
            <td className="fw-bold text-nowrap">
              {calculateTotalAmount(slots)} /-
            </td>
            <td></td>
          </tr>
        </tfoot>
      </Table>
      {warningMessage && (
        <div className="text-danger text-center mb-3">{warningMessage}</div>
      )}
    </div>
  );
};

const Review = ({ data }) => {
  const { formattedDate, formattedTime } = formatDateTime(data.createdAt);
  console.log(data)
  // Assuming the data object contains these properties
  const { companyName, name, phoneNo, email, createdAt, userMessage, slots } =
    data;


  const totalAmount = slots
    ? slots.reduce((acc, slot) => acc + slot.price, 0)
    : 0;

  return (
    <div className="p-2">
      <Card className="p-4 shadow-sm border-0 mb-4">
        <h5 className="fw-bold mb-4">Booking Summary</h5>

        <Row className="mb-3">
          <Col sm={4} className="fw-bold text-muted">Company Name:</Col>
          <Col sm={8} className="text-dark">{companyName}</Col>
        </Row>

        <Row className="mb-3">
          <Col sm={4} className="fw-bold text-muted">Full Name:</Col>
          <Col sm={8} className="text-dark">{name}</Col>
        </Row>

        <Row className="mb-3">
          <Col sm={4} className="fw-bold text-muted">Phone:</Col>
          <Col sm={8} className="text-dark">{phoneNo}</Col>
        </Row>

        <Row className="mb-3">
          <Col sm={4} className="fw-bold text-muted">Email:</Col>
          <Col sm={8} className="text-dark">{email}</Col>
        </Row>

        <Row className="mb-3">
          <Col sm={4} className="fw-bold text-muted">Date:</Col>
          <Col sm={8} className="text-dark">{formattedDate}</Col>
        </Row>

        <Row className="mb-3">
          <Col sm={4} className="fw-bold text-muted">Time:</Col>
          <Col sm={8} className="text-dark">{formattedTime}</Col>
        </Row>

        <Row className="mb-3">
          <Col sm={4} className="fw-bold text-muted">Purpose:</Col>
          <Col sm={8} className="text-dark">{userMessage}</Col>
        </Row>
      </Card>

      <div>
        <h5 className="fw-bold mb-4">Booking Details</h5>

        {/* Booking Details Table */}
        <Table bordered hover responsive>
          <thead className="table-primary">
            <tr>
              <th>Country</th>
              <th>Date</th>
              <th>Slot Duration</th>
              <th>Amount</th>
            </tr>
          </thead>
          <tbody>
            {slots && slots.length > 0 ? (
              slots.map((slot, index) => (
                <tr key={index}>
                  <td>{slot.country}</td>
                  <td>{slot.date}</td>
                  <td>{slot.time} - ({slot.duration})</td>
                  <td>₹ {slot.price}/-</td>
                </tr>
              ))
            ) : (
              <tr>
                <td colSpan="4" className="text-center">
                  No slot details available
                </td>
              </tr>
            )}
          </tbody>
          <tfoot>
            <tr className="table-secondary">
              <td colSpan="3" className="fw-bold text-end">
                Total Amount:
              </td>
              <td>₹ {totalAmount}/-</td>
            </tr>
          </tfoot>
        </Table>
      </div>
      <div>
        <h5 className="fw-bold mb-4">Terms & Conditions</h5>
        <hr />
        <ul>
          <li>You may edit the selected countries at any time before finalizing your booking. Please review your selections carefully to ensure accuracy.</li>
          <li>If you wish to designate two representatives for separate country regions, please add the second representative before proceeding with the booking.</li>
          <li>Once the booking is confirmed, no changes to the selected countries will be permitted.</li>
        </ul>
      </div>
    </div>
  );
};

const formatDateTime = (isoString) => {
  const date = new Date(isoString);
  const formattedDate = date.toLocaleDateString('en-GB');
  const formattedTime = date.toLocaleTimeString('en-GB', {
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
  });
  return { formattedDate, formattedTime };
};
