import { Route, Routes } from "react-router-dom";
import "./App.css";
import Login from "./pages/auth/Login";
import UserDashboard from "./pages/Dashbaord/UserDashboard";

import InternationalCollabration from "./collabration/InternationalCollabration";
import PrivateRoute from "./components/routes/privateRoute.js";
import ProtectedRoute from "./components/routes/protectedRoute.js";
import ForgetPassword from "./pages/auth/ForgetPassword";
import Registerpage1 from "./pages/auth/Register/Registerpage1";
import Registerpage2 from "./pages/auth/Register/Registerpage2";
import RegisterPage3 from "./pages/auth/Register/RegisterPage3";
import TrialUserRegister from "./pages/auth/Register/TrialUserRegister.js";
import DisplayComDir from "./pages/Independent/CommercialDirectory.jsx";
import EcoVerification from "./pages/Independent/ECoVerification.js";
import RegisterDocumentUpload from "./pages/Independent/RegisterDocumentUpload.js";
import PageNotFound from "./pages/PageNotFound";
import AdBooking from "./pages/sideBarPage/AdBooking.js";
import ListOfAgreement from "./pages/sideBarPage/ArbitrationCenterPages/ListOfAgreement.js";
import PanelName from "./pages/sideBarPage/ArbitrationCenterPages/PanelName.js";
import RaiseYourDispute from "./pages/sideBarPage/ArbitrationCenterPages/RaiseYourDispute/RaiseYourDispute.js";
import RulesPolicies from "./pages/sideBarPage/ArbitrationCenterPages/RulesPolicies.js";
import ChangePassword from "./pages/sideBarPage/ChangePassword";
import CollobrationPortal from "./pages/sideBarPage/CollobrationPortal.js";
import CommercialDirectory from "./pages/sideBarPage/CommercialDirectory";
import CompanyDetails from "./pages/sideBarPage/CompanyDetails";
import B2bEventRequest from "./pages/sideBarPage/EventsPages/B2bEventRequest.js";
import ChamberEventBooking from "./pages/sideBarPage/EventsPages/ChamberEventBooking.js";
import MarketingInformation from "./pages/sideBarPage/MarketingInformation";
import PersonalDetails from "./pages/sideBarPage/PersonalDetails";
import DigitalLibrary from "./pages/sideBarPage/PublicationsPages/DigitalLibrary.js";
import DigitalPrint from "./pages/sideBarPage/ServicesPages/DigitalPrint.js";
import ECO from "./pages/sideBarPage/ServicesPages/e-co";
import MembershipServices from "./pages/sideBarPage/ServicesPages/MembershipServices";
import RecommendationLetter from "./pages/sideBarPage/ServicesPages/RecommendationLetter";
import UpgradeMembership from "./pages/sideBarPage/UpgradeMembership.js";
import Wallet from "./pages/sideBarPage/Wallet";
import BuissnessAdviceToken from "./pages/sideBarPage/wings/BuissnessAdviceWing/BuissnessAdviceToken.js";
import BuissnessAdviceWing from "./pages/sideBarPage/wings/BuissnessAdviceWing/BuissnessAdviceWIng.js";
import BusinessWingRequest from "./pages/sideBarPage/wings/BusinessWingRequest.js";
import EventAndSeminarWing from "./pages/sideBarPage/wings/EventSeminarWing/EventAndSeminarWing.js";
import EventSeminarWingToken from "./pages/sideBarPage/wings/EventSeminarWing/EventSeminarWingToken.js";
import EventWingRequest from "./pages/sideBarPage/wings/EventWingRequest.js";
import ExportWing from "./pages/sideBarPage/wings/ExportWing/ExportWing";
import ExportWingToken from "./pages/sideBarPage/wings/ExportWing/ExportWingToken.js";
import ExportWingRequest from "./pages/sideBarPage/wings/ExportWingRequest.js";
import HrSupportWing from "./pages/sideBarPage/wings/HRWing/HrSupportWIng.js";
import HrWingToken from "./pages/sideBarPage/wings/HRWing/HrwingToken.js";
import HrWingRequest from "./pages/sideBarPage/wings/HrWingRequest.js";
import LegalWing from "./pages/sideBarPage/wings/LegalWing/LegalWing.js";
import LegalWingToken from "./pages/sideBarPage/wings/LegalWing/LegalWingToken";
import LegalWingRequest from "./pages/sideBarPage/wings/LegalWingRequest.js";
import ProfessionalWing from "./pages/sideBarPage/wings/ProfessionalWing/ProfessionalWIng.js";
import ProfessionalWingToken from "./pages/sideBarPage/wings/ProfessionalWing/ProfessionalWingToken.js";
import ProfessionalWingRequest from "./pages/sideBarPage/wings/ProfessionalWingRequest.js";

import ArbitrationSignup from "./pages/Independent/RegistrationForm/ArbitrationSignup.js";
import CollaborationSignup from "./pages/Independent/RegistrationForm/CollaborationSignup.js";
import B2bInterestedClient from "./pages/sideBarPage/b2b/B2bInterestedClient.js";
import B2bConnect from "./pages/sideBarPage/EventsPages/B2bConnect.js";
import B2bVirtualForum from "./pages/sideBarPage/EventsPages/B2bVirtualForum.js";

function App() {
  return (
    <>
      <Routes>
        <Route path="/login" element={<Login />} />
        {/* //Dashboard pages */}
        <Route path="/register" element={<Registerpage1 />} />
        <Route path="/register-2" element={<Registerpage2 />} />
        <Route path="/register-3" element={<RegisterPage3 />} />
        <Route path="/request-trial-version" element={<TrialUserRegister />} />
        <Route path="/forget-password" element={<ForgetPassword />} />
        {/*========================== PROFILE pages =================*/}
        <Route
          path="/User-dashboard"
          element={<PrivateRoute Component={UserDashboard} />}
        />
        <Route
          path="/personal-details"
          element={
            <ProtectedRoute
              Component={PersonalDetails}
              path="/personal-details"
            />
          }
        />
        <Route
          path="/company-details"
          element={
            <ProtectedRoute
              Component={CompanyDetails}
              path="/company-details"
            />
          }
        />
        <Route
          path="/marketing-information"
          element={
            <ProtectedRoute
              Component={MarketingInformation}
              path="/marketing-information"
            />
          }
        />
        <Route
          path="/change-password"
          element={
            <ProtectedRoute
              Component={ChangePassword}
              path="/change-password"
            />
          }
        />

        {/*========================================= services-routes====================*/}

        <Route
          path="/trade-document"
          element={<ProtectedRoute Component={ECO} path="/e-co" />}
        />
        <Route path="/digital-print" element={<DigitalPrint />} />

        <Route
          path="/membership-services"
          element={
            <ProtectedRoute
              Component={MembershipServices}
              path="/membership-services"
            />
          }
        />
        <Route
          path="/recommendation-letters"
          element={
            <ProtectedRoute
              Component={RecommendationLetter}
              path="/recommendation-letters"
            />
          }
        />
        <Route
          path="/commercial-directory"
          element={
            <ProtectedRoute
              Component={CommercialDirectory}
              path="/commercial-directory"
            />
          }
        />

        <Route
          path="/wallet"
          element={<ProtectedRoute Component={Wallet} path="/wallet" />}
        />
        <Route
          path="/collaboration-portal"
          element={
            <ProtectedRoute
              Component={CollobrationPortal}
              path="/collaboration-portal"
            />
          }
        />

        {/*================================= b2b Connect Request form =====================================*/}
        <Route path="/b2b-event-request" element={<B2bEventRequest />} />

        {/*================================= Collaboration Registration form =====================================*/}
        <Route path="/collaboration-signup" element={<CollaborationSignup />} />

        {/*================================= Collaboration Registration form =====================================*/}
        <Route path="/arbitration-signup" element={<ArbitrationSignup />} />

        {/*================================= Legal Request form =====================================*/}
        <Route path="/legal-wing-request" element={<LegalWingRequest />} />

        {/*================================= Export Request form =====================================*/}
        <Route path="/export-wing-request" element={<ExportWingRequest />} />

        {/*================================= Professional Request form =====================================*/}
        <Route
          path="/professional-wing-request"
          element={<ProfessionalWingRequest />}
        />

        {/*================================= Hr Request form =====================================*/}
        <Route
          path="/hr-wing-request"
          element={<HrWingRequest />}
        />


         {/*================================= Business Request form =====================================*/}
         <Route
          path="/business-wing-request"
          element={<BusinessWingRequest />}
        />


         {/*================================= Event Request form =====================================*/}
         <Route
          path="/event-wing-request"
          element={<EventWingRequest />}
        />

        {/*================================= wings =====================================*/}
        <Route
          path="/export-wing"
          element={
            <ProtectedRoute Component={ExportWing} path="/export-wing" />
          }
        />
        <Route
          path="/legal-wing"
          element={<ProtectedRoute Component={LegalWing} path="/legal-wing" />}
        />
        <Route
          path="/hr-support-wing"
          element={
            <ProtectedRoute Component={HrSupportWing} path="/hr-support-wing" />
          }
        />
        <Route
          path="/buissness-advice-wing"
          element={
            <ProtectedRoute
              Component={BuissnessAdviceWing}
              path="/buissness-advice-wing"
            />
          }
        />
        <Route
          path="/professional-wing"
          element={
            <ProtectedRoute
              Component={ProfessionalWing}
              path="/professional-wing"
            />
          }
        />
        <Route
          path="/event-seminar-wing"
          element={
            <ProtectedRoute
              Component={EventAndSeminarWing}
              path="/event-seminar-wing"
            />
          }
        />
        <Route
          path="/export-wing-token"
          element={
            <PrivateRoute
              Component={ExportWingToken}
              path="/export-wing-token"
            />
          }
        />
        <Route
          path="/Legal-wing-token"
          element={
            <PrivateRoute Component={LegalWingToken} path="/Legal-wing-token" />
          }
        />

        <Route
          path="/Professional-wing-token"
          element={
            <PrivateRoute
              Component={ProfessionalWingToken}
              path="/Professional-wing-token"
            />
          }
        />
        <Route
          path="/Buissness-wing-token"
          element={
            <PrivateRoute
              Component={BuissnessAdviceToken}
              path="/Buissness-wing-token"
            />
          }
        />
        <Route
          path="/Hr-wing-token"
          element={
            <PrivateRoute Component={HrWingToken} path="/Hr-wing-token" />
          }
        />

        <Route
          path="/Event-wing-token"
          element={
            <PrivateRoute
              Component={EventSeminarWingToken}
              path="/Event-wing-token"
            />
          }
        />
        {/* ======================Arbitration Center=====================================*/}
        <Route
          path="/rules-policies"
          element={
            <ProtectedRoute Component={RulesPolicies} path="/rules-policies" />
          }
        />
        <Route
          path="/panel-name"
          element={<ProtectedRoute Component={PanelName} path="/panel-name" />}
        />
        <Route
          path="/raise-your-dispute"
          element={
            <ProtectedRoute
              Component={RaiseYourDispute}
              path="/raise-your-dispute"
            />
          }
        />
        <Route
          path="/list-of-agreement"
          element={
            <ProtectedRoute
              Component={ListOfAgreement}
              path="/list-of-agreement"
            />
          }
        />
        {/* ================================== Events=====================================*/}
        <Route
          path="/chember-event-booking"
          element={
            <ProtectedRoute
              Component={ChamberEventBooking}
              path="/chember-event-booking"
            />
          }
        />
        <Route
          path="/b2b-connect"
          element={
            <ProtectedRoute Component={B2bConnect} path="/b2b-connect" />
          }
        />
        <Route
          path="/aecci-virtual-b2b-forum"
          element={
            <ProtectedRoute Component={B2bVirtualForum} path="/aecci-virtual-b2b-forum" />
            // <B2bVirtualForum />
          }
        />
        <Route  
          path="/interested-participate"
          element={
            <ProtectedRoute Component={B2bInterestedClient} path="/interested-participate" />
          }
        />

        {/* ================================== Publications =====================================*/}
        <Route
          path="/digital-library"
          element={
            // <ProtectedRoute Component={DigitalLibrary} path="/digital-library" />
            <DigitalLibrary />
          }
        />

        {/* ================================== ad Booking =====================================*/}

        <Route path="/book-your-ad" element={<AdBooking />} />

        {/*=============================  digital user=====================================*/}
        <Route path="/upgrade-membership" element={<UpgradeMembership />} />

        {/* routes for collabration */}
        <Route
          path="/international-collabration"
          element={<InternationalCollabration />}
        />
        <Route path="/e-co-verification" element={<EcoVerification />} />

        {/* ---------------route for indepent pages --------------------------------------- */}
        <Route
          path="/register-document-upload"
          element={<RegisterDocumentUpload />}
        />
        <Route path="/commercial-dir" element={<DisplayComDir />} />
        <Route path="*" element={<PageNotFound />} />
      </Routes>
    </>
  );
}

export default App;
